import { useDispatch, useSelector } from 'react-redux';
import { setComparisonOpen, setCompareBuildings } from 'config/appSlice';
import {
    Box,
    Drawer,
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import { capitalize, emptyValue, numberFormat } from 'utils/formatter';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        margin: theme.spacing(0, 0.75, 0, -1.25),

        '& + hr': {
            marginRight: theme.spacing(1.5),
        },
    },
    toolbar: {
        borderBottom: '1px solid #ddd',
    },
    drawer: {
        width: '100%',
    },
    table: {
        '& .MuiTableCell-head': {
            fontWeight: 700,
        },
    },
}));
function BuildingComparison() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const buildings = useSelector((state) => state.app.compareBuildings);
    const comparisonOpen = useSelector((state) => state.app.comparisonOpen);

    const handleComparisonToggle = () => {
        dispatch(setComparisonOpen(!comparisonOpen));
    };

    const handleAddToComparison = (info) => {
        dispatch(setCompareBuildings(info));
    };

    return (
        <Drawer
            role='presentation'
            variant='persistent'
            anchor='right'
            open={comparisonOpen}
            onClose={handleComparisonToggle}
            PaperProps={{
                className: classes.drawer,
            }}
        >
            <Toolbar />
            <Toolbar variant='dense' className={classes.toolbar}>
                <IconButton
                    edge='start'
                    className={classes.menuButton}
                    color='inherit'
                    aria-label='menu'
                    onClick={handleComparisonToggle}
                >
                    <CloseIcon />
                </IconButton>
                <Box mr={3}>
                    <Typography color='primary' component='h2' variant='subtitle1' noWrap>
                        Property Comparison
                    </Typography>
                </Box>
            </Toolbar>
            <TableContainer>
                <Table className={classes.table} aria-label='Property Comparison Table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell>Building ID</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Property Use Type</TableCell>
                            <TableCell>Year Built</TableCell>
                            <TableCell align='right'>
                                Gross Floor Area (ft<sup>2</sup>)
                            </TableCell>
                            <TableCell align='right'>Energy STAR<sup>&reg;</sup> Score</TableCell>
                            <TableCell align='right'>
                                WN Site EUI (kBtu/ft<sup>2</sup>)
                            </TableCell>
                            <TableCell align='right'>
                                National Median Site EUI (kBtu/ft<sup>2</sup>)
                            </TableCell>
                            <TableCell>Remove</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {buildings && buildings.length > 0 ? (
                            buildings.map((row) => (
                                <TableRow key={row.building_id}>
                                    <TableCell component='th' scope='row'>
                                        {capitalize(row.street)} {row.postal_code}
                                    </TableCell>
                                    <TableCell>{row.building_id}</TableCell>
                                    <TableCell>
                                        {capitalize(row.normalized_status)}
                                    </TableCell>
                                    <TableCell>
                                        {capitalize(row.primary_use_type)}
                                    </TableCell>
                                    <TableCell>{row.year_built}</TableCell>
                                    <TableCell align='right'>
                                        {numberFormat(row.gross_floor_area)}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {emptyValue(row.energy_star_score)}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {emptyValue(row.site_eui)}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {emptyValue(row.national_median_site_eui)}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            color='secondary'
                                            aria-label='remove'
                                            onClick={() => handleAddToComparison(row)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell align='center' colSpan={12}>
                                    No Selected Properties to Compare
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Drawer>
    );
}

export default BuildingComparison;
