import { createSlice } from '@reduxjs/toolkit';
import defaultFilters from './defaultValues';
import { getUtilityCost } from 'utils/utilityCosts';
import { isEmpty } from 'utils/checkValue';
import { chain } from 'lodash';

const constructData = (data) => {
    const parents = chain(data)
        .filter((n) => n.latitude !== null && n.parent_property)
        .map((b) => {
            const status = b.status.toLowerCase();
            let normalized_status;

            switch (status) {
                case 'not submitted':
                    normalized_status = 'out of compliance';
                    break;
                case 'in progress':
                    normalized_status = 'pending revisions';
                    break;
                default:
                    normalized_status = status;
                    break;
            }
            return Object.assign(b, {
                utility_cost: getUtilityCost(b),
                normalized_status,
                gross_floor_area: isEmpty(b.gfa_reported)
                    ? b.gross_floor_area
                    : parseInt(b.gfa_reported),
                latitude: Number(b.latitude),
                longitude: Number(b.longitude),
                site_eui: b.weather_normalized_site_eui ?? b.site_eui,
            });
        })
        .value();
    return parents;
};

const slice = createSlice({
    name: 'app',
    initialState: {
        data: [],
        options: {},
        filters: defaultFilters(),
        selectedFilter: 'normalized_status',
        selectedCity: '',
        selectedBuilding: null,
        selectedYear: '2024',
        reportedProperties: {
            required: true,
            voluntary: true,
        },
        comparisonOpen: false,
        compareBuildings: [],
        legend: null,
        error: null,
        bottomSheetOpen: false,
    },
    reducers: {
        setData: (state, action) => {
            state.data = constructData(action.payload);
        },
        setOptions: (state, action) => {
            state.options = action.payload;
        },
        setDataFilters: (state, action) => {
            const { key, value } = action.payload;
            state.filters[key].values = value;
        },
        setDataFiltersToDefault: (state) => {
            state.filters = defaultFilters();
        },
        setSelectedFilter: (state, action) => {
            state.selectedFilter = action.payload;
        },
        setSelectedBuilding: (state, action) => {
            state.selectedBuilding = action.payload;
        },
        setSelectedYear: (state, action) => {
            state.selectedYear = action.payload;
        },
        setReportedProperties: (state, action) => {
            const { key, value } = action.payload;
            state.reportedProperties[key] = value;
        },
        setComparisonOpen: (state, action) => {
            state.comparisonOpen = action.payload;
        },
        setCompareBuildings: (state, action) => {
            const building = action.payload;
            const index = state.compareBuildings.findIndex(
                (b) => b.building_id === building.building_id
            );
            const collection = [...state.compareBuildings];
            if (index > -1) {
                collection.splice(index, 1);
            } else {
                collection.push(action.payload);
            }
            state.compareBuildings = collection;
        },
        setLegend: (state, action) => {
            state.legend = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setBottomSheetOpen: (state, action) => {
            state.bottomSheetOpen = action.payload;
        },
    },
});

export default slice.reducer;

export const setData = (payload) => ({
    type: 'app/setData',
    payload,
});
export const setOptions = (payload) => ({
    type: 'app/setOptions',
    payload,
});
export const setDataFilters = (payload) => ({
    type: 'app/setDataFilters',
    payload,
});
export const setDataFiltersToDefault = () => ({
    type: 'app/setDataFiltersToDefault',
});
export const setSelectedFilter = (payload) => ({
    type: 'app/setSelectedFilter',
    payload,
});
export const setSelectedCity = (payload) => ({
    type: 'app/setSelectedCity',
    payload,
});
export const setSelectedBuilding = (payload) => ({
    type: 'app/setSelectedBuilding',
    payload,
});
export const setSelectedYear = (payload) => ({
    type: 'app/setSelectedYear',
    payload,
});
export const setReportedProperties = (payload) => ({
    type: 'app/setReportedProperties',
    payload,
});
export const setComparisonOpen = (payload) => ({
    type: 'app/setComparisonOpen',
    payload,
});
export const setCompareBuildings = (payload) => ({
    type: 'app/setCompareBuildings',
    payload,
});
export const setLegend = (payload) => ({
    type: 'app/setLegend',
    payload,
});
export const setError = (payload) => ({
    type: 'app/setError',
    payload,
});
export const setBottomSheetOpen = (payload) => ({
    type: 'app/setBottomSheetOpen',
    payload,
});
