import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataFilters, setError } from 'config/appSlice';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import TouchstoneInput from 'components/common/TouchstoneInput';
import { city } from 'config/defaultValues';
import { setViewState } from '@carto/react-redux';
import useIsMounted from 'utils/useIsMounted';
import ApiService from 'data/apiService';

const defaultValues = city();
const KEY = defaultValues.key;

function SelectCity() {
    const dispatch = useDispatch();
    const isMounted = useIsMounted();
    const selectedCity = useSelector((state) => state.app.filters[KEY].values);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        const api = new ApiService().map();
        const fetchData = async () => {
            try {
                const { data } = await api.getCities();
                let collection = [];
                if (data) {
                    collection = data;
                }
                if (isMounted.current) setCities(collection);
            } catch (error) {
                dispatch(setError(error.message));
            }
        };

        fetchData();
    }, [isMounted, dispatch]);

    useEffect(() => {
        let lat, long, zoom;
        const match = cities.find((el) => {
            return el.extra_info.prefix === selectedCity;
        });

        if(match) {
            lat = parseFloat(match.latitude);
            long = parseFloat(match.longitude);
            zoom = 11.5;
        }
        else {
            lat =  44.475069;
            long = -92.940174;
            zoom = 8.5;
        }

        dispatch(setViewState({
            latitude: lat,
            longitude: long,
            zoom: zoom,
            transitionDuration: 500,
        }))
    }, [selectedCity, dispatch, cities]);

    const handleChange = (event) => {
        dispatch(setDataFilters({ key: KEY, value: event.target.value }));
    };

    return (
        <FormControl>
            <InputLabel id='select-city-label' shrink>
                City
            </InputLabel>
            <Select
                labelId='select-city-label'
                value={selectedCity}
                onChange={handleChange}
                input={<TouchstoneInput />}
                displayEmpty={true}
                renderValue={(selected) => {
                    if (selected === '') return 'All Cities';
                    // we have the prefix in selected, need to get the name
                    const match = cities.find((el) => {
                        return el.extra_info.prefix === selected;
                    });

                    if(match) {
                        return match.name;
                    }
                }}
            >
                <MenuItem key='' value=''>All Cities</MenuItem>
                {cities && cities.map((c) => (
                    <MenuItem key={c.extra_info.prefix} value={c.extra_info.prefix}>
                        {c.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default SelectCity;
