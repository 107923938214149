import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core';
import HorizontalHistogramWidgetUI from 'components/widgets/HorizontalHistogramWidgetUI';

function CompareEui(props) {
    const theme = useTheme();
    const { site, nationalMedian } = props;

    const series = [
        { color: theme.palette.neutral[10], value: parseFloat(nationalMedian) },
        { color: theme.palette.neutral[6], value: parseFloat(site) },
    ];

    const axis = ['National Median Site', 'Site'];

    const handleFormat = (val) => {
        const data = val[0];
        return `
            ${data.name} EUI <br />
            ${data.value} (kBtu/ft<sup>2</sup>)
        `;
    };

    return (
        <HorizontalHistogramWidgetUI
            id='compareEUI'
            name='compare_eui_to_site_eui'
            data={series}
            dataAxis={axis}
            isLoading={series.length === 0}
            height={40}
            tooltipFormatter={handleFormat}
        />
    );
}

CompareEui.propTypes = {
    site: PropTypes.string.isRequired,
    nationalMedian: PropTypes.string.isRequired,
};

export default CompareEui;
