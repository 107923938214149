import { useEffect, useState } from 'react';
import {
    AppBar,
    Box,
    Button,
    Divider,
    Drawer,
    Hidden,
    Grid,
    IconButton,
    Link,
    makeStyles,
    Toolbar,
    Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation } from 'react-router-dom';
import BeyondBenchmarking from 'components/info/BeyondBenchmarking';
import logo from 'assets/img/HC-wordmark-full-color-png.png';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    navBar: {
        zIndex: theme.zIndex.modal - 1,
        overflow: 'hidden',
        borderbottom: '1px solid #ddd',
    },
    alert: {
        zIndex: theme.zIndex.modal - 1,
        borderRadius: 0,
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
    topBar: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.secondary,
    },
    bottomBar: {
        borderTop: '1px solid #ddd',
        backgroundColor: theme.palette.background.default,
    },
    menuButton: {
        margin: theme.spacing(0, 0.75, 0, -1.25),

        '& + hr': {
            marginRight: theme.spacing(1.5),
        },
    },
    navMenu: {
        padding: theme.spacing(1.5),
    },
    drawer: {
        minWidth: 260,
    },
    title: {
        '& img': {
            height: '2rem',
            marginRight: theme.spacing(3),
            verticalAlign: 'bottom',
        },
    },
}));

const NavigationMenu = ({ column: vertical, handleBeyondToggle }) => {
    const classes = useStyles();
    return (
        <>
            <Grid
                container
                direction={vertical ? 'column' : 'row'}
                className={!vertical ? '' : classes.navMenu}
                alignItems={'center'}
            >
                <div className={classes.grow} />
                <Button
                    color='primary'
                    href='https://www.hennepin.us/benchmarking'
                    target='_blank'
                    size='small'
                >
                    Program Information
                </Button>
            </Grid>
        </>
    );
};

function Header() {
    const classes = useStyles();
    const location = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [beyondOpen, setBeyondOpen] = useState(true);

    useEffect(() => {
        setDrawerOpen(false);
        setBeyondOpen(false);
    }, [location]);

    const handleBeyondToggle = () => {
        setBeyondOpen(!beyondOpen);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <>
            <AppBar position='static' className={classes.navBar}>
                <Toolbar className={classes.topBar}>
                    <Hidden mdUp>
                        <IconButton
                            edge='start'
                            className={classes.menuButton}
                            color='inherit'
                            aria-label='menu'
                            onClick={handleDrawerToggle}
                        >
                            {drawerOpen ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                        <Divider orientation='vertical' />
                    </Hidden>
                    <Link href='https://www.hennepin.us' className={classes.title}>
                        <img src={logo} alt='Hennepin County Logo' />
                    </Link>
                    <Box mr={3}>
                        <Typography color='primary' component='h1' variant='subtitle1' noWrap>
                            Efficient Buildings Collaborative
                        </Typography>
                        <Typography
                            color='textSecondary'
                            component='p'
                            variant='caption'
                            noWrap
                        >
                            Benchmarking Map
                        </Typography>
                    </Box>
                    <Hidden smDown>
                        <NavigationMenu handleBeyondToggle={handleBeyondToggle} />
                    </Hidden>
                    <Hidden mdUp>
                        <Drawer
                            variant='temporary'
                            anchor='left'
                            open={drawerOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            PaperProps={{
                                className: classes.drawer,
                            }}
                        >
                            <Grid
                                container
                                direction='column'
                                justifycontent='space-between'
                                item
                                sm
                            >
                                <NavigationMenu
                                    column={true}
                                    handleBeyondToggle={handleBeyondToggle}
                                />
                            </Grid>
                        </Drawer>
                    </Hidden>
                </Toolbar>
                <Drawer
                    variant='temporary'
                    anchor='right'
                    open={beyondOpen}
                    onClose={handleBeyondToggle}
                >
                    <Toolbar variant='dense'>
                        <IconButton
                            edge='start'
                            className={classes.menuButton}
                            color='inherit'
                            aria-label='menu'
                            onClick={handleBeyondToggle}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    <BeyondBenchmarking />
                </Drawer>
            </AppBar>
        </>
    );
}

export default Header;
