import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setBottomSheetOpen,
    setData,
    setError,
    setLegend,
    setComparisonOpen,
    setDataFiltersToDefault,
} from 'config/appSlice';
import { Box, Button, Divider, Grid, Typography, makeStyles, useTheme } from '@material-ui/core';
import { compliance_status } from 'config/defaultValues';
import { legendSetAmount } from 'utils/setChartDefaults';
import WrapperWidgetUI from 'components/widgets/WrapperWidgetUI';
import EnergyStar from 'components/info/histograms/EnergyStar';
import PropertyType from 'components/info/selects/PropertyType';
import Search from 'components/common/Search';
import SelectFilter from 'components/info/selects/SelectFilter';
import SelectYear from 'components/info/selects/SelectYear';
import SiteEUI from 'components/info/histograms/SiteEUI';
import SquareFeet from 'components/info/histograms/SquareFeet';
import YearBuit from 'components/info/histograms/YearBuilt';
import WUI from 'components/info/histograms/WUI';
import ApiService from 'data/apiService';
import SelectCity from 'components/info/selects/SelectCity';

const useStyles = makeStyles((theme) => ({
    contentSection: {
        padding: theme.spacing(1.5, 3, 3),
    },
    margin: {
        marginBottom: theme.spacing(3),
    },
    marginTop: {
        marginTop: theme.spacing(2),
    },
    marginBoth: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        margin: theme.spacing(0, 0.75, 0, -1.25),

        '& + hr': {
            marginRight: theme.spacing(1.5),
        },
    },
}));

function BuildingList() {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();

    const selectedYear = useSelector((state) => state.app.selectedYear);
    const reportedProperties = useSelector((state) => state.app.reportedProperties);
    const buildings = useSelector((state) => state.app.compareBuildings);
    const comparisonOpen = useSelector((state) => state.app.comparisonOpen);
    const selectedFilter = useSelector((state) => state.app.selectedFilter);

    useEffect(() => {
        const api = new ApiService().map();
        const fetchData = async () => {
            let collection = [];
            try {
                const { data } = await api.getMap(selectedYear, reportedProperties);
                if (data) {
                    collection = data;
                }
            } catch (error) {
                dispatch(setError(error.message));
            }

            dispatch(setData(collection));
        };

        fetchData();
    }, [dispatch, selectedYear, reportedProperties]);

    useEffect(() => {
        const defaultValues = compliance_status();
        const KEY = defaultValues.key;
        const COLORS = defaultValues.colors;
        const ranges = defaultValues.ranges;

        if (selectedFilter === KEY) {
            dispatch(
                setLegend({
                    title: 'Covered Buildings',
                    categories: legendSetAmount(theme, COLORS, ranges),
                })
            );
        }
    }, [dispatch, selectedFilter, theme]);

    useEffect(() => {
        dispatch(setBottomSheetOpen(false));
    }, [dispatch]);

    const handleComparisonToggle = () => {
        dispatch(setComparisonOpen(!comparisonOpen));
    };

    const clearFilters = () => {
        dispatch(setDataFiltersToDefault());
    }

    return (
        <Grid direction={'column'} container>
            <Box className={classes.contentSection}>
                <Typography variant='subtitle1' component='h2'>
                    Filter Covered Buildings
                </Typography>
                <Typography color='textSecondary' variant='caption' component='h3'>
                    Define the filters to show the desired covered buildings
                </Typography>
                <Search />
                <SelectYear />
                <PropertyType />
                <SelectCity />
                <SelectFilter />
                <Button
                    size='small'
                    color='primary'
                    variant='contained'
                    className={classes.marginTop}
                    onClick={handleComparisonToggle}
                >
                    View Comparison {buildings.length > 0 && <>({buildings.length})</>}
                </Button>
                <Button
                    size='small'
                    color='primary'
                    variant='contained'
                    className={classes.marginBoth}
                    onClick={clearFilters}
                >
                    Clear Filters
                </Button>
            </Box>
            <Divider />
            <WrapperWidgetUI
                title='Energy and Water Performance Metrics'
                titleComponent='h4'
            >
                <EnergyStar />
                <SiteEUI />
                <WUI />
            </WrapperWidgetUI>
            <Divider />
            <WrapperWidgetUI title='Property Information' titleComponent='h4'>
                <SquareFeet />
                <YearBuit />
            </WrapperWidgetUI>
            <Divider />
            <Divider className={classes.margin} />
            <div className={classes.grow} />
            <Box className={classes.contentSection}>
                <Typography
                    color='textSecondary'
                    variant='caption'
                    component='p'
                    align='center'
                >
                    &copy; {new Date().getFullYear()} - Touchstone IQ
                </Typography>
            </Box>
        </Grid>
    );
}

export default BuildingList;
