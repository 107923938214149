import { useSelector } from 'react-redux';
import { GeoJsonLayer } from '@deck.gl/layers';
import { useState } from 'react';
import geojson from 'data/sources/city_limits.json';

export const CITY_LIMITS_LAYER_ID = 'cityLimitsLayer';

function CityLimitsLayer() {
    const [mapGeoJson] = useState(geojson);
    const { cityLimitsLayer } = useSelector((state) => state.carto.layers);
    if (cityLimitsLayer && mapGeoJson) {
        return new GeoJsonLayer({
            id: CITY_LIMITS_LAYER_ID,
            data: mapGeoJson,
            stroked: true,
            pickable: false,
            getLineWidth: 10,
            getLineColor: [0, 0, 0],
            getFillColor: [0, 0, 0, 5],
        });
    }
}

export default CityLimitsLayer;
