import { makeStyles } from '@material-ui/core';
import { Avatar, Box, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        maxWidth: '550px',
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    title: {
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
    subTitle: {
        marginTop: theme.spacing(1),
    },
    number: {
        color: '#ffffff',
        backgroundColor: theme.palette.grey[500],
    },
}));

function BeyondBenchmarking() {
    const classes = useStyles();

    return (
        <section className={classes.wrapper}>
            <Typography
                variant='h6'
                component='h1'
                className={classes.title}
                gutterBottom
            >
                Additional FREE Resources to Improve
            </Typography>
            <Paper variant='outlined' className={classes.paper}>
                <Box display='flex' alignItems='start'>
                    <Avatar className={classes.number}>1</Avatar>
                    <Box width='100%' ml={2}>
                        <Typography
                            color='textSecondary'
                            variant='subtitle1'
                            component='h2'
                            className={classes.subTitle}
                            gutterBottom
                        >
                            Energy Savings Assessment
                        </Typography>
                        <Typography variant='body2' component='p' gutterBottom>
                            Learn about options for facility specific services offered by
                            Utilities by going to the{' '}
                            <a
                                href='https://efficiencyworks.org/business/advising-and-assessments'
                                target='_blank'
                                rel='noopener noreferrer'
                                name='advising-and-assessments'
                            >
                                Efficiency Works web page for advising and assessments
                            </a>
                            . For further questions contact:
                        </Typography>
                        <Typography variant='body2' component='p'>
                            David Suckling, PMP <br />
                            Business Energy Efficiency / Integrated Design Assistance{' '}
                            <br />
                            Fort Collins Utilities <br />
                            <a
                                href='mailto:dsuckling@fcgov.com?subject=Fort Collins Utilities'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                dsuckling@fcgov.com
                            </a>{' '}
                            <br />
                            970-416-4251
                        </Typography>
                    </Box>
                </Box>
            </Paper>
            <Paper variant='outlined' className={classes.paper}>
                <Box display='flex' alignItems='start'>
                    <Avatar className={classes.number}>2</Avatar>
                    <Box width='100%' ml={2}>
                        <Typography
                            color='textSecondary'
                            variant='subtitle1'
                            component='h2'
                            className={classes.subTitle}
                            gutterBottom
                        >
                            Make Changes and Start Saving
                        </Typography>
                        <Typography variant='body2' component='div' gutterBottom>
                            <ol>
                                <li>
                                    Get started today with a lighting project.{' '}
                                    <a
                                        href='https://efficiencyworks.org/resources/find-a-service-provider'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Efficiency Works contractors
                                    </a>{' '}
                                    are ready to connect you with Fort Collins rebates
                                    that pays for a substantial part of the cost.
                                    <ul>
                                        <li>
                                            Learn more about cash rebates for lighting and
                                            other upgrades on the{' '}
                                            <a
                                                href='https://efficiencyworks.org/business/rebates'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                name='rebates'
                                            >
                                                Efficiency Works rebates web page
                                            </a>
                                            .
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Typography
                                        variant='body2'
                                        component='p'
                                        gutterBottom
                                    >
                                        For further questions contact:
                                    </Typography>
                                    <Typography variant='body2' component='p'>
                                        David Suckling, PMP <br />
                                        Business Energy Efficiency / Integrated Design
                                        Assistance <br />
                                        Fort Collins Utilities <br />
                                        <a
                                            href='mailto:dsuckling@fcgov.com?subject=Fort Collins Utilities'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            dsuckling@fcgov.com
                                        </a>{' '}
                                        <br />
                                        970-416-4251
                                    </Typography>
                                </li>
                            </ol>
                        </Typography>
                    </Box>
                </Box>
            </Paper>
            <Paper variant='outlined' className={classes.paper}>
                <Box display='flex' alignItems='start'>
                    <Avatar className={classes.number}>3</Avatar>
                    <Box width='100%' ml={2}>
                        <Typography
                            color='textSecondary'
                            variant='subtitle1'
                            component='h2'
                            className={classes.subTitle}
                            gutterBottom
                        >
                            Beyond Benchmarking
                        </Typography>
                        <Typography variant='body2' component='div'>
                            <ul>
                                <li>
                                    <a
                                        href='https://www.fcgov.com/utilities/business/improve-efficiency/rebates-incentives/integrated-design-assistance/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Design Assistance
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://copace.com/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        C-PACE Financing
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='http://www.energystar.gov/buildings/lp_finder'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Energy Star Licensed Professional Finder
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://efficiencyworks.org/business/building-tune-up'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Building Tune-Up
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://efficiencyworks.org/business/rebates'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Building Envelope Improvements
                                    </a>
                                </li>
                            </ul>
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </section>
    );
}

export default BeyondBenchmarking;
