import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataFilters, setLegend } from 'config/appSlice';
import HistogramWidgetUI from 'components/widgets/HistogramWidgetUI';
import {
    Grid,
    IconButton,
    Tooltip,
    Typography,
    makeStyles,
    useTheme,
} from '@material-ui/core';
import { Range, createSliderWithTooltip } from 'rc-slider';
import useDebounce from 'utils/debounce';
import { energy_star_score } from 'config/defaultValues';
import { isEmpty } from 'utils/checkValue';
import {
    histogramDefaults,
    setRangeCategories,
    legendDefaults,
} from 'utils/setChartDefaults';
import InfoIcon from '@material-ui/icons/Info';
import filterData from 'utils/filterData';
import { chain } from 'lodash';

const defaultValues = energy_star_score();
const KEY = defaultValues.key;
const SLOTS = defaultValues.slots;
const MIN = defaultValues.min;
const MAX = defaultValues.max;
const COLORS = defaultValues.colors;
const RANGES = defaultValues.ranges;

const useStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(1, 0, 1),
        display: 'block',
    },
    slider: {
        margin: theme.spacing(1, 0, 2),
    },
}));

const RangeWithTooltip = createSliderWithTooltip(Range);

function EnergyStar() {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();

    const data = useSelector((state) => state.app.data);
    const filters = useSelector((state) => state.app.filters);
    const selectedFilter = useSelector((state) => state.app.selectedFilter);

    const valuesRef = useRef([MIN, MAX]);
    const [values, setValues] = useState([MIN, MAX]);
    const [series, setSeries] = useState([]);

    const axis = setRangeCategories(RANGES, false, false);

    useEffect(() => {
        valuesRef.current = values;
    }, [values]);

    useEffect(() => {
        let series = [];
        if (data && data.length > 0) {
            const filteredData = filterData(data, filters, [KEY]);
            if (filters[KEY].values[0] !== valuesRef.current[0] || filters[KEY].values[1] !== valuesRef.current[1]) {
                setValues(filters[KEY].values);
            }

            series = chain(filteredData)
                .filter(
                    (t) => t[KEY] != null && t[KEY] > 0 && t.status === 'in compliance'
                )
                .reduce((arry, e) => {
                    const value = e[KEY];
                    for (let i = 0; i < RANGES.length; i++) {
                        const [low, high] = RANGES[i];
                        if (value >= low && value < high + 1) {
                            arry[i].value++;
                        }
                    }
                    return arry;
                }, histogramDefaults(theme, COLORS, SLOTS))
                .value();
        }
        setSeries(series);
    }, [theme, data, filters]);

    useEffect(() => {
        if (selectedFilter === KEY) {
            dispatch(
                setLegend({
                    title: 'ENERGY STAR<sup>&reg;</sup> Score',
                    categories: legendDefaults(theme, COLORS, SLOTS, axis),
                })
            );
        }
    }, [dispatch, selectedFilter, axis, theme]);

    const handleFormat = (val) => {
        const data = val[0];
        return `
            ENERGY STAR<sup>&reg;</sup> Score <br />
            ${data.name}: ${data.value} properties
        `;
    };

    const invokeDebounced = useDebounce(
        () => dispatch(setDataFilters({ key: KEY, value: values })),
        1000
    );

    useEffect(invokeDebounced, [values, invokeDebounced]);

    const handleChange = (newValue) => {
        if (!isEmpty(newValue)) setValues(newValue);
    };

    const valueLabelFormat = (val) => {
        return val !== MAX ? val : `${val}`;
    };

    const ariaValueText = (val) => {
        return `${val}`;
    };

    return (
        <>
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Typography
                    component='h5'
                    color='textSecondary'
                    variant='subtitle2'
                    className={classes.title}
                >
                    ENERGY STAR<sup>&reg;</sup> Score
                </Typography>
                <Tooltip
                    title='ENERGY STAR® is a national energy performance rating system. A score of 50 represents median energy performance, while a score of 75 or higher indicates your building is a top performer. Learn more about ENERGY STAR® by clicking the information icon.'
                    placement='top'
                >
                    <IconButton
                        aria-label='ENERGY STAR Info'
                        size='small'
                        color='primary'
                        href='https://www.energystar.gov/buildings/benchmark'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <InfoIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
            </Grid>
            <HistogramWidgetUI
                id='buildingEnergyStar'
                name='energystar_score'
                data={series}
                dataAxis={axis}
                isLoading={series.length === 0}
                height={100}
                tooltipFormatter={handleFormat}
            />
            <RangeWithTooltip
                value={values}
                ariaLabelGroupForHandles={[
                    'Min EnergyStar Score',
                    'Max EnergyStar Score',
                ]}
                ariaValueTextFormatterGroupForHandles={ariaValueText}
                onChange={handleChange}
                min={MIN}
                max={MAX}
                tipFormatter={valueLabelFormat}
                className={classes.slider}
            />
        </>
    );
}

export default EnergyStar;
