import { useDispatch, useSelector } from 'react-redux';
import { setSelectedFilter } from 'config/appSlice';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import TouchstoneInput from 'components/common/TouchstoneInput';

function SelectFilter() {
    const dispatch = useDispatch();
    const selectedFilter = useSelector((state) => state.app.selectedFilter);

    const handleChange = (event) => {
        dispatch(setSelectedFilter(event.target.value));
    };

    return (
        <FormControl>
            <InputLabel id='select-filter-label' shrink>
                Map View
            </InputLabel>
            <Select
                labelId='select-filter-label'
                value={selectedFilter}
                onChange={handleChange}
                input={<TouchstoneInput />}
            >
                <MenuItem value={'normalized_status'}>Covered Buildings</MenuItem>
                <MenuItem value={'energy_star_score'}>ENERGY STAR<sup>&reg;</sup> Score</MenuItem>
                <MenuItem value={'site_eui'}>Site Energy Use Intensity</MenuItem>
                <MenuItem value={'water_intensity_total'}>Water Use Intensity</MenuItem>
                <MenuItem value={'gross_floor_area'}>Property Size</MenuItem>
                <MenuItem value={'year_built'}>Year Built</MenuItem>
            </Select>
        </FormControl>
    );
}

export default SelectFilter;
