import { isEmpty } from 'utils/checkValue';
const getUtilityCost = (b) => {
    let cost = 0;
    const gfa = b.gross_floor_area;
    if (gfa === 0) return;
    const keys = {
        water_use: (b) => {
            const value = parseFloat(b.water_use);
            if (!isEmpty(value) && value > 0) {
                cost += (value * 2.5485) / gfa;
            }
        },
        electricity_use_grid_purchase_kwh: (b) => {
            const value = parseFloat(b.electricity_use_grid_purchase_kwh);
            if (!isEmpty(value) && value > 0) {
                cost += (value * 0.09) / gfa;
            }
        },
        natural_gas_use_therms: (b) => {
            const value = parseFloat(b.natural_gas_use_therms);
            if (!isEmpty(value) && value > 0) {
                cost += (value * 0.677) / gfa;
            }
        },
        district_steam_use: (b) => {
            const value = parseFloat(b.district_steam_use);
            if (!isEmpty(value) && value > 0) {
                cost += ((value / 1194000) * 18.55) / gfa;
            }
        },
        district_chilled_water_use: (b) => {
            const value = parseFloat(b.district_chilled_water_use);
            if (!isEmpty(value) && value > 0) {
                cost += ((value / 12) * 0.225) / gfa;
            }
        },
    };
    for (let key in keys) {
        keys[key](b);
    }
    return cost;
};

export { getUtilityCost };
