import React from 'react';
import { Navigate } from 'react-router-dom';

import Main from 'components/views/Main';
import Buildings from 'components/views/buildings/Buildings';
import BuildingList from 'components/views/buildings/BuildingList';
import NotFound from 'components/views/NotFound';
// Auto import

const routes = [
    {
        path: `/`,
        element: <Main />,
        children: [
            {
                path: '/',
                element: <Buildings />,
                children: [{ path: '/', element: <BuildingList /> }],
            },
            // Auto import routes
        ],
    },
    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to={`/404`} /> },
];

export default routes;
